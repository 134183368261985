var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Login"}},[_c('div',{staticClass:"content_login"},[_vm._m(0),_c('div',{staticClass:"B1"},[_vm._m(1),_c('div',{staticClass:"B1F2"},[_c('div',{staticClass:"form_login"},[_c('h1',[_vm._v("LOGIN")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.login(
                _vm.email,
                _vm.password
              )}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","name":"email","id":"email","placeholder":'Correo electrónico',"required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","name":"pass","id":"pass","minlength":"8","placeholder":'Contraseña',"required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('button',{attrs:{"variant":"outline-secondary","type":"submit"}},[_vm._v("INICIAR SESIÓN")]),(_vm.status=='error')?_c('div',{staticClass:"message_error"},[_c('p',[_vm._v(" "+_vm._s(this.message)+" ")]),_c('img',{attrs:{"src":require("../assets/alert.png")}})]):_vm._e()])])])]),_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"B0"},[_c('img',{attrs:{"src":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fondo-logo"},[_c('img',{attrs:{"src":require("../assets/logo_2.png"),"alt":"Logo sitio web"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"B2"},[_c('img',{attrs:{"src":""}})])
}]

export { render, staticRenderFns }